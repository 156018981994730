import axios from "axios";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import apiBasic from "../service/APIBasic";

// 後端 API URL 配置
const BACKEND_API_URL = `${apiBasic.conn}://${apiBasic.url}/api/getTranslate`; // 這裡替換為你的後端 API 地址

function removeEmptyKeys(obj) {
  // 遍歷所有屬性和子物件
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      // 如果是物件，遞迴調用此函數
      removeEmptyKeys(obj[key]);
    } else {
      // 如果值為空字符串，刪除該屬性
      if (obj[key] === "" || obj[key] === " ") {
        delete obj[key];
      } else if (typeof obj[key] === "string") {
        // 替換字符串中的 '\\n' 為 '\n'
        obj[key] = obj[key].replace(/\\n/g, "\n");
      }
    }
  });

  return obj;
}

export const locales = [
  { name: "bahasa Indonesia", key: "id-ID" },
  { name: "Tiếng Việt", key: "vi-VN" },
  { name: "English", key: "en-US" },
  { name: "簡體中文", key: "zh-CN" },
  { name: "繁體中文", key: "zh-TW" },
  { name: "แบบไทย", key: "th-TH" },
];

i18n
  .use(Backend) // 使用後端載入語言包
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "zh-TW", // 後備語言
    preload: ["zh-TW"],
    // saveMissing: true, // 將缺失的翻譯上傳到後端
    load: "currentOnly",
    backend: {
      loadPath: `${BACKEND_API_URL}?lang={{lng}}`, // 從後端加載語言包的路徑
      request: async (options, url, payload, callback) => {
        await axios
          .get(url)
          .then((res) => {
            callback(null, {
              status: res.data.status,
              data: removeEmptyKeys(res?.data?.data?.translation) || {},
            });
          })
          .catch(() => {
            callback("error", {
              status: 500,
              data: {},
              // data: removeEmptyKeys(zh_TW),
            });
          });
      },
      parse: (data) => {
        return JSON.parse(data || "{}").data;
      },
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false, // 不使用 React Suspense
    },
  });

export default i18n;
